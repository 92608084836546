import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxCurrencyModule } from 'ngx-currency';
import { CountdownModule } from 'ngx-countdown';

import { AuthorizeRoleDirective } from './../core/authentication/authorize.role.directive';
import { AuthorizePrivilegeDirective } from './../core/authentication/authorize.privilege.directive';

import { NavigationComponent } from './navigation/navigation.component';
import { SearchComponent } from './navigation/search/search.component';
import { InlineNotesComponent } from './inline-notes/inline-notes.component';
import { CalendarEventsComponent } from './navigation/calendar-events/calendar-events.component';
import { EditNotificationDialogComponent } from './navigation/edit-notification-dialog/edit-notification-dialog.component';

import { SharedComponent } from './shared.component';
import { TemplatesComponent } from './templates/templates.component';
import { AddTemplateComponent } from './templates/add-template/add-template.component';
import { LookupListsComponent } from './lookup-lists/lookup-lists.component'
import { EditLookupListDialogComponent } from './lookup-lists/edit-lookup-list-dialog/edit-lookup-list-dialog.component';
import { DurationPipe } from '../shared/duration.pipe';
import { FullNamePipe } from '../shared/full-name.pipe';
import { StatusPipe } from './status.pipe';
import { TypePipe } from './type.pipe';
import { PhonePipe } from './phone.pipe';
import { AddressPipe } from './address.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { SsnPipe } from './ssn.pipe';
import { PaymentDaysPipe } from './payment-days.pipe';
import { ExperianDatePipe } from './experian-date.pipe';
import { FormatFileSizePipe } from './format-file-size.pipe';
import { MaterialModule } from './../material';
import { StipulationsComponent } from './stipulations/stipulations.component';
import { StipulationNotesDialogComponent } from './stipulations/stipulation-notes-dialog/stipulation-notes-dialog.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { AddressComponent } from './address/address.component';
import { AddressAutocompleteDirective } from './address-autocomplete.directive';
import { SpecialCharactersFirstAndLastDirective } from './special-characters-first-and-last.directive';
import { BankSelectionComponent } from './bank-selection/bank-selection.component';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { TradelineComponent } from './credit-report/tradeline/tradeline.component';
import { DecisionLogicRequestDialogComponent } from './decision-logic-request-dialog/decision-logic-request-dialog.component';
import { ApplicationEventConfirmationDialogComponent } from './application-event-confirmation-dialog/application-event-confirmation-dialog.component';
import { ApplicationEventRecipientListComponent } from './application-event-recipient-list/application-event-recipient-list.component';
import { LoadingButtonDirective } from '@shared/loading-button.directive';
import { EditTemplateDialogComponent } from './templates/edit-template-dialog/edit-template-dialog.component';
import { ViewTemplateDialogComponent } from './templates/view-template-dialog/view-template-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SyndicationOffersComponent } from './syndication-offers/syndication-offers.component';
import { FundingSyndicationsComponent } from './funding-syndications/funding-syndications.component';
import { PaymentFeesComponent } from './payment-fees/payment-fees.component';
import { EditFeeDialogComponent } from './edit-fee-dialog/edit-fee-dialog.component';
import { EditCreditDialogComponent } from './edit-credit-dialog/edit-credit-dialog.component';
import { DisableControlDirective } from './disable-control.directive';
import { ViewNotificationDialogComponent } from './view-notification-dialog/view-notification-dialog.component';
import { FundingPaymentMethodsComponent } from './funding-payment-methods/funding-payment-methods.component';
import { EditWhiteLabelDialogComponent } from "../user/settings/white-labels/edit-white-label-dialog/edit-white-label-dialog.component";
import { OffersListComponent } from './offers-list/offers-list.component';
import { AddContactDialogComponent } from './add-contact-dialog/add-contact-dialog.component';
import { UnusedFeesPipe } from './unused-fees.pipe';
import { FloatingNotesComponent } from './floating-notes/floating-notes.component';
import { ExportApplicationsDialogComponent } from './export-applications-dialog/export-applications-dialog.component';
import { LoadingMessageComponent } from './loading-message/loading-message.component';
import { RefinanceComponent } from './refinance/refinance.component';
import { UploadAttachmentComponent } from './upload-attachment/upload-attachment.component';
import { SyndicationFundDialogComponent } from './syndication-fund-dialog/syndication-fund-dialog.component';
import { StayAliveDialogComponent } from './stay-alive-dialog/stay-alive-dialog.component';
import { DynamicStatusComponent } from './dynamic-status/dynamic-status.component';
import { ApplicationHeadingComponent } from './application-heading/application-heading.component';
import { SicNaicsComponent } from './sic-naics/sic-naics.component';
import { IndustryPickerDialogComponent } from './sic-naics/industry-picker-dialog/industry-picker-dialog.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';

import { FundingNotificationsEmailsComponent } from '../shared/funding-notifications-emails/funding-notifications-emails.component';
import { FundingNotificationsSmsComponent } from '../shared/funding-notifications-sms/funding-notifications-sms.component';

import { InputTrimDirective } from './input-trim.directive';
import { PlaidTokenRequestDialogComponent } from './plaid-token-request-dialog/plaid-token-request-dialog.component';
import { PlaidReportComponent } from './plaid-report/plaid-report.component';
import { AddBankInfoModalComponent } from './add-bank-info-modal/add-bank-info-modal.component';
import { UploadFilesListComponent } from './upload-files-list/upload-files-list.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { SelectStipulationDialogComponent } from './select-stipulation-dialog/select-stipulation-dialog.component';
import { CreditRequestDialogComponent } from './credit-request-dialog/credit-request-dialog-component';
import { CreditReportComponent } from './credit-report/credit-report.component';
import { CamelToLabelPipe } from './camel-to-label.pipe';
import { OpenFileButtonComponent } from './open-file-button/open-file-button.component';
import { TruncateFilenamePipe } from '@shared/truncate-filename.pipe';
import { PaymentPlanComponent } from './payment-plan/payment-plan.component';
import { AddSyndicationsComponent } from './syndication/add-syndications/add-syndications.component';
import { AddSyndicationOffersComponent } from './syndication/add-syndication-offers/add-syndication-offers.component';
import { AddSyndicationsDialogComponent } from './syndication/add-syndications-dialog/add-syndications-dialog.component';
import { AddSyndicationOffersDialogComponent } from './syndication/add-syndication-offers-dialog/add-syndication-offers-dialog.component';
import { EditManualSyndicationOfferDialogComponent } from './edit-manual-syndication-offer-dialog/edit-manual-syndication-offer-dialog.component';
import { EditSyndicationOfferDialogComponent } from './edit-syndication-offer-dialog/edit-syndication-offer-dialog.component';
import { ManualSyndicationOfferDialogComponent } from './manual-syndication-offer-dialog/manual-syndication-offer-dialog.component';
import { SyndicationOfferDialogComponent } from './syndication-offer-dialog/syndication-offer-dialog.component';
import { NumberFormatDirective } from './number-format.directive';
import { SyndicatorWalletsComponent } from './syndicator-wallets/syndicator-wallets.component';
import { ViewSyndicatorWalletComponent } from './syndicator-wallets/view/view-syndicator-wallet.component';
import { AddSyndicatorWalletComponent } from './syndicator-wallets/add/add-wallet.component';
import { AddManualWalletTransactionComponent } from './syndicator-wallets/view/add-manual-wallet-transaction/add-manual-wallet-transaction.component';
import { LimitDecimalsForInputDirective } from './limit-decimals-for-input.directive';
import { UserTaskListComponent } from './user-task-list/user-task-list.component';
import { AvailableFundingsForSyndicationTableComponent } from './available-fundings-for-syndication-table/available-fundings-for-syndication-table.component';
import { SyndicatorFundingsTableComponent } from './syndicator-fundings-table/syndicator-fundings-table.component';
import { DownloadReportsDialogComponent } from './download-reports-dialog/download-reports-dialog.component';
import { SelectGeneratedReportsComponent } from './select-generated-reports/select-generated-reports.component';
import { UtcDatePipe } from './utc-date.pipe';
import { TextAreaAutosizeDirective } from './textarea-autosize.directive';
import { SearchSelectgComponent } from './search-select/search-select.component';
import { ValidateDateDirective } from './validate-date.directive';
import { ExtendedDatePipe } from './extended-date.pipe';
import { OrdinalsPipe } from './ordinals.pipe';
import { SelectWhiteLabelsDialogComponent } from './select-white-labels-dialog/select-white-labels-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DropzoneModule,
    RouterModule,
    NgbTypeaheadModule,
    NgxMaskModule.forRoot(),
    NgxSliderModule,
    CKEditorModule,
    NgxCurrencyModule,
    CountdownModule,
    MatProgressBarModule
  ],
  declarations: [
    SharedComponent,
    TemplatesComponent,
    AddTemplateComponent,
    NavigationComponent,
    SearchComponent,
    InlineNotesComponent,
    CalendarEventsComponent,
    AuthorizeRoleDirective,
    AuthorizePrivilegeDirective,
    DurationPipe,
    FullNamePipe,
    StatusPipe,
    TypePipe,
    PhonePipe,
    AddressPipe,
    SsnPipe,
    PaymentDaysPipe,
    EllipsisPipe,
    ExperianDatePipe,
    FormatFileSizePipe,
    StipulationsComponent,
    StipulationNotesDialogComponent,
    RangeSliderComponent,
    AddressComponent,
    AddressAutocompleteDirective,
    BankSelectionComponent,
    MessageDialogComponent,
    ErrorDialogComponent,
    DeleteConfirmationDialogComponent,
    AddUserDialogComponent,
    DecisionLogicRequestDialogComponent,
    TradelineComponent,
    ApplicationEventConfirmationDialogComponent,
    ApplicationEventRecipientListComponent,
    LoadingButtonDirective,
    EditTemplateDialogComponent,
    ViewTemplateDialogComponent,
    ConfirmDialogComponent,
    SyndicationOffersComponent,
    FundingSyndicationsComponent,
    PaymentFeesComponent,
    EditFeeDialogComponent,
    EditCreditDialogComponent,
    LookupListsComponent,
    EditLookupListDialogComponent,
    DisableControlDirective,
    SpecialCharactersFirstAndLastDirective,
    ViewNotificationDialogComponent,
    FundingPaymentMethodsComponent,
    OffersListComponent,
    AddContactDialogComponent,
    UnusedFeesPipe,
    FloatingNotesComponent,
    ExportApplicationsDialogComponent,
    EditNotificationDialogComponent,
    LoadingMessageComponent,
    EditWhiteLabelDialogComponent,
    RefinanceComponent,
    UploadAttachmentComponent,
    SyndicationFundDialogComponent,
    StayAliveDialogComponent,
    DynamicStatusComponent,
    ApplicationHeadingComponent,
    SicNaicsComponent,
    IndustryPickerDialogComponent,
    PasswordStrengthComponent,
    FundingNotificationsEmailsComponent,
    FundingNotificationsSmsComponent,
    InputTrimDirective,
    PlaidTokenRequestDialogComponent,
    PlaidReportComponent,
    AddBankInfoModalComponent,
    InputTrimDirective,
    UploadFilesListComponent,
    SelectStipulationDialogComponent,
    CreditRequestDialogComponent,
    CreditReportComponent,
    CamelToLabelPipe,
    OpenFileButtonComponent,
    TruncateFilenamePipe,
    PaymentPlanComponent,
    AddSyndicationsComponent,
    AddSyndicationOffersComponent,
    AddSyndicationsDialogComponent,
    AddSyndicationOffersDialogComponent,
    EditManualSyndicationOfferDialogComponent,
    EditSyndicationOfferDialogComponent,
    ManualSyndicationOfferDialogComponent,
    SyndicationOfferDialogComponent,
    NumberFormatDirective,
    SyndicatorWalletsComponent,
    ViewSyndicatorWalletComponent,
    AddSyndicatorWalletComponent,
    AddManualWalletTransactionComponent,
    LimitDecimalsForInputDirective,
    UserTaskListComponent,
    AvailableFundingsForSyndicationTableComponent,
    SyndicatorFundingsTableComponent,
    DownloadReportsDialogComponent,
    SelectGeneratedReportsComponent,
    UtcDatePipe,
    SearchSelectgComponent,
    TextAreaAutosizeDirective,
    OrdinalsPipe,
    ValidateDateDirective,
    ExtendedDatePipe,
    SelectWhiteLabelsDialogComponent
  ],
  exports: [
    SharedComponent,
    TemplatesComponent,
    AddTemplateComponent,
    NavigationComponent,
    InlineNotesComponent,
    AuthorizeRoleDirective,
    AuthorizePrivilegeDirective,
    DurationPipe,
    FullNamePipe,
    StatusPipe,
    TypePipe,
    PhonePipe,
    AddressPipe,
    SsnPipe,
    PaymentDaysPipe,
    EllipsisPipe,
    ExperianDatePipe,
    FormatFileSizePipe,
    StipulationsComponent,
    RangeSliderComponent,
    AddressComponent,
    MessageDialogComponent,
    BankSelectionComponent,
    AddUserDialogComponent,
    ErrorDialogComponent,
    ApplicationEventRecipientListComponent,
    LoadingButtonDirective,
    SpecialCharactersFirstAndLastDirective,
    EditTemplateDialogComponent,
    ViewTemplateDialogComponent,
    ConfirmDialogComponent,
    SyndicationOffersComponent,
    FundingSyndicationsComponent,
    PaymentFeesComponent,
    EditFeeDialogComponent,
    LookupListsComponent,
    EditLookupListDialogComponent,
    DisableControlDirective,
    ViewNotificationDialogComponent,
    FundingPaymentMethodsComponent,
    OffersListComponent,
    AddContactDialogComponent,
    UnusedFeesPipe,
    FloatingNotesComponent,
    EditNotificationDialogComponent,
    LoadingMessageComponent,
    RefinanceComponent,
    UploadAttachmentComponent,
    DynamicStatusComponent,
    ApplicationHeadingComponent,
    SicNaicsComponent,
    PasswordStrengthComponent,
    FundingNotificationsEmailsComponent,
    FundingNotificationsSmsComponent,
    InputTrimDirective,
    PlaidReportComponent,
    AddBankInfoModalComponent,
    InputTrimDirective,
    AddressAutocompleteDirective,
    UploadFilesListComponent,
    SelectStipulationDialogComponent,
    CreditReportComponent,
    CamelToLabelPipe,
    OpenFileButtonComponent,
    TruncateFilenamePipe,
    PaymentPlanComponent,
    AddSyndicationsComponent,
    AddSyndicationOffersComponent,
    AddSyndicationsDialogComponent,
    AddSyndicationOffersDialogComponent,
    EditManualSyndicationOfferDialogComponent,
    EditSyndicationOfferDialogComponent,
    ManualSyndicationOfferDialogComponent,
    SyndicationOfferDialogComponent,
    NumberFormatDirective,
    SyndicatorWalletsComponent,
    ViewSyndicatorWalletComponent,
    AddSyndicatorWalletComponent,
    AddManualWalletTransactionComponent,
    LimitDecimalsForInputDirective,
    UserTaskListComponent,
    AvailableFundingsForSyndicationTableComponent,
    SyndicatorFundingsTableComponent,
    DownloadReportsDialogComponent,
    SelectGeneratedReportsComponent,
    UtcDatePipe,
    TextAreaAutosizeDirective,
    OrdinalsPipe,
    SearchSelectgComponent,
    ValidateDateDirective,
    ExtendedDatePipe
  ],
  providers: [
    CurrencyPipe,
    {
      provide: DatePipe,
      useClass: ExtendedDatePipe
    },
    FullNamePipe
  ],
  entryComponents: [
    MessageDialogComponent,
    ErrorDialogComponent,
    DeleteConfirmationDialogComponent,
    AddUserDialogComponent,
    DecisionLogicRequestDialogComponent,
    ApplicationEventConfirmationDialogComponent,
    EditTemplateDialogComponent,
    ViewTemplateDialogComponent,
    ConfirmDialogComponent,
    EditWhiteLabelDialogComponent,
    EditFeeDialogComponent,
    EditCreditDialogComponent,
    EditLookupListDialogComponent,
    ViewNotificationDialogComponent,
    AddContactDialogComponent,
    ExportApplicationsDialogComponent,
    StipulationNotesDialogComponent,
    EditNotificationDialogComponent,
    SyndicationFundDialogComponent,
    StayAliveDialogComponent,
    IndustryPickerDialogComponent,
    PlaidTokenRequestDialogComponent,
    AddBankInfoModalComponent,
    SelectStipulationDialogComponent,
    CreditRequestDialogComponent,
    AddSyndicationsComponent,
    AddSyndicationOffersComponent,
    AddSyndicationsDialogComponent,
    AddSyndicationOffersDialogComponent,
    EditManualSyndicationOfferDialogComponent,
    EditSyndicationOfferDialogComponent,
    ManualSyndicationOfferDialogComponent,
    SyndicationOfferDialogComponent,
    AddSyndicatorWalletComponent,
    AddManualWalletTransactionComponent,
    DownloadReportsDialogComponent
  ]
})
export class SharedModule { }
