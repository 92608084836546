import { Component, OnDestroy, OnInit } from '@angular/core';

import { RuleSet, Rule, Conditional, Result, Argument, Offer, ResultOffer, OfferField } from '@core/models';

import { ApplicationService, ScoreCardService } from '@core/services';
import { AuthService } from '@core/authentication/auth.service';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ScorecardRuleSet } from '@core/models/score-cards';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'oiq-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.scss']
})
export class ScoreCardComponent implements OnInit {

  tenantId: number;

  form: FormArray;

  applicationList: any[];

  addRuleButtonsShow: any = {};

  logicConditional = {
    type: 'logic',
    operator: ['NOT', 'OR', 'AND'],
    conditional: ''
  };

  operators = [
    { value: '>', type: 'single' },
    { value: '>=', type: 'single' },
    { value: '<', type: 'single' },
    { value: '<=', type: 'single' },
    { value: 'in', type: 'double' },
    { value: 'between', type: 'double' }
  ];

  parameters = [
    { name: 'FICO', operatorType: 'single', value: 'fico' },
    { name: 'Time in business', operatorType: 'single', value: 'tib' },
    { name: 'SIC', operatorType: 'double', value: 'sic' },
    { name: 'Average ledger', operatorType: 'single', value: 'averageLedger' },
    { name: 'Deposits', operatorType: 'single', value: 'deposits' },
    { name: 'Trade lines', operatorType: 'single', value: 'tradelines' }
  ];

  logicalParameters = [
   {name: 'AND', operatorType: 'single', value: 'AND'},
   {name: 'OR', operatorType: 'single', value: 'OR'},
   {name: 'NOT', operatorType: 'single', value: 'NOT'},
  ];

  dbConditional = {
    type: 'db',
    operator: ['>', '>=', '<', '<=', 'in', 'between'],
    conditional: [
      { name: 'FICO', value: 'fico' },
      { name: 'Time in business', value: 'tib' },
      { name: 'SIC', value: 'sic' },
      { name: 'Average ledger', value: 'averageLedger' },
      { name: 'Deposits', value: 'deposits' },
      { name: 'Trade lines', value: 'tradelines' }
    ],
    arguments: this.determineArgs()
  }

  scoreConditional = {
    type: 'score',
    operator: ['>', '>=', '<', '<=', 'in', 'between'],
    conditional: '',
    arguments: this.determineArgs()
  }

  offerFieldNames = [
    {value: 'percentOfGross', key: 'Percent Of Gross'},
    {value: 'buyRate', key: 'Buy Rate'},
    {value: 'termLength', key: 'Term Length'},
    {value: 'commisionPercent', key: 'Commision Percent'}
  ]

  filteredOperators: { [key: number]: any[] } = {};
  nestedFilteredOperators: { [key: string]: any[] } = {};

  public dbFieldsList;

  public loading = false;
  public ruleSetDraft;
  public ruleSetType: string;
  public offerAdded = false;
  private eventSubscription: Subscription;
  inputType: string;

  ruleDraft = false;
  mutableParameters = [
    { name: 'FICO', operatorType: 'single', value: 'fico' },
    { name: 'Time in business', operatorType: 'single', value: 'tib' },
    { name: 'SIC', operatorType: 'double', value: 'sic' },
    { name: 'Average ledger', operatorType: 'single', value: 'averageLedger' },
    { name: 'Deposits', operatorType: 'single', value: 'deposits' },
    { name: 'Trade lines', operatorType: 'single', value: 'tradelines' }
  ];

  parametersMap = new Map<number, any[]>();

  dynamicParameters = [];

  constructor(
    private scoreCardService: ScoreCardService,
    private authService: AuthService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private applicationService: ApplicationService
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.tenantId = this.authService.getUser().tenantId;
    this.ruleSetDraft = false;
    this.getRules();
    this.listDbField();

    this.form = new FormArray([]);
  }

  getRules() {
    this.loading = true;
    this.scoreCardService.listRules(this.tenantId)
    .subscribe((r: any) => {
      const ruleSets: ScorecardRuleSet[] = r.rows;
      if (r.rows && r.rows.length) {
        this.setRuleSets(ruleSets);
      }
    });
    this.loading = false;
  }

  setRuleSets(ruleSets: any[]): void {
    const ruleSetFormArray = this.form as FormArray;
    ruleSets.forEach((ruleSet, ruleSetIndex) => {
      if (ruleSet._id) {
        ruleSetFormArray.push(this.fb.group({
          _id: [ruleSet._id],
          tenantId: [ruleSet.tenantId],
          ruleSetName: [ruleSet.ruleSetName],
          ruleSetDescription: [ruleSet.ruleSetDescription],
          ruleSetType: [ruleSet.ruleSetType],
          scoreWeight: [ruleSet.scoreWeight],
          useSet: [ruleSet.useSet],
          rules: this.setRules(ruleSet.rules, ruleSetIndex)
        }));
      }
    });
  }

  setRules(rules: any[], ruleSetIndex): FormArray {
    const ruleFormArray = this.fb.array([]);
    rules.forEach((rule, ruleIndex) => {
      if(rule.conditional.arguments) {
        //db or score rule
        ruleFormArray.push(this.fb.group({
          conditional: this.fb.group({
            conditional: [rule.conditional.conditional],
            type: [rule.conditional.type],
            operator: [rule.conditional.operator],
            arguments: this.createArgumentsFormArray(rule.conditional.arguments)
          }),
          result: this.fb.group({
            score: [rule.result.score],
            applyToAll: [rule.applyToAll] || undefined,
            offers: this.setOffersArray(rule.result?.offers) || undefined
          })
        }));

        // Initialize filtered operators for loaded rules
        this.initializeOperatorsForRule(rule, ruleSetIndex, ruleIndex);
  
        // Initialize parameters map for this rule set if not already done
        this.initializeParametersForRuleSet(rule, ruleSetIndex);
      } else {
        //logic rule
        ruleFormArray.push(this.fb.group({
          conditional: this.fb.group({
            conditional: this.createConditionalFormArray(rule.conditional.conditional),
            type: [rule.conditional.type],
            logicOperator: [rule.conditional.logicOperator],
          }),
          result: this.fb.group({
            score: [rule.result.score],
            applyToAll: [rule.applyToAll] || undefined,
            offers: this.setOffersArray(rule.result?.offers) || undefined
          })
        }));
  
        // Initialize filtered operators for loaded logic rules
        this.initializeOperatorsForRule(rule, ruleSetIndex, ruleIndex);
  
        // Initialize parameters map for this rule set if not already done
        this.initializeParametersForRuleSet(rule, ruleSetIndex);
      }
    });
  
    return ruleFormArray;
  }
  
  initializeOperatorsForRule(rule, ruleSetIndex, ruleIndex): void {
    // Check and add dynamic operator if not found
    const operatorExists = this.operators.find(op => op.value === rule.conditional.operator);
    if (!operatorExists) {
      this.operators.push({ value: rule.conditional.operator, type: rule.conditional.type }); // Assuming 'single' type for dynamic operators
    }
  
    let parameter = this.parameters.find(param => param.value === rule.conditional.conditional);

    if (!parameter) {
      const param = this.parameters.find(parameter => parameter.value == rule.conditional.conditional)
      parameter = { name: param?.name ? param.name : rule.conditional.conditional, operatorType: 'single', value: rule.conditional.conditional };
      this.parameters.push(parameter);
    }

    // Set filtered operators for the current rule set and rule
    this.filteredOperators[`${ruleSetIndex}_${ruleIndex}`] = this.operators.filter(operator => {
      return parameter.operatorType === 'single' || parameter.operatorType === operator.type;
    });
  }
  
  initializeParametersForRuleSet(rule, ruleSetIndex): void {
    // Initialize parameters for each rule set (score, decision, etc.)
    let parametersForRuleSet = this.parametersMap.get(ruleSetIndex) || [];
    
    let parameter = parametersForRuleSet.find(param => param.value === rule.conditional.conditional);

    if (!parameter) {
      const param = this.parameters.find(parameter => parameter.value == rule.conditional.conditional)
      parameter = { name: param?.name ? param.name : rule.conditional.conditional, operatorType: 'single', value: rule.conditional.conditional };
      parametersForRuleSet.push(parameter);
    }
  
    // Set the parameters for this rule set index
    this.parametersMap.set(ruleSetIndex, parametersForRuleSet);
  }

  checkIfDecision(ruleSet: FormGroup): boolean {
    const ruleSetType: string = ruleSet.get('ruleSetType').value;
    return ruleSetType == 'decision';
  }

  determineArgs() {

  }

  addRuleSet() {
    this.ruleSetDraft = true;
    this.form.push(new RuleSet(this.tenantId))
  }

  showRuleTypes(index) {
    this.addRuleButtonsShow[index] = !this.addRuleButtonsShow[index];
  }

  addRule(ruleSet, index, type) {
    this.ruleDraft = true;
  
    // If the rule set is of type 'decision', ensure the parameters are correctly initialized
    if (ruleSet.value.ruleSetType === 'decision') {
      const existingParameters = this.parameters.map(param => param.value);
  
      // Add parameters for each other ruleSetName, excluding the current decision ruleset
      const ruleSets = this.form.value.filter(r => r.ruleSetName !== ruleSet.value.ruleSetName && r.ruleSetType !== 'decision');
      ruleSets.forEach(rSet => {
        if (!existingParameters.includes(rSet.ruleSetName)) {
          this.parameters.push({
            name: rSet.ruleSetName,
            value: rSet.ruleSetName,
            operatorType: 'single'
          });
        }
      });
    }
  
    switch (type) {
      case 'logic':
        ruleSet.get('rules').push(new Rule('logic'));
        break;
  
      case 'db':
        ruleSet.get('rules').push(new Rule('db'));
        break;
  
      case 'score':
        ruleSet.get('rules').push(new Rule('score'));
        break;
  
      default:
        break;
    }
  
    this.addRuleButtonsShow[index] = false;
  }
  
  

  getRuleSetsNames(form, index) {
    const array = form.value;
    const modifiedArray = array.splice(index, 1);

    modifiedArray.map(ruleSet => {
      this.parameters.push({
        name: ruleSet.ruleSetName,
        value: ruleSet.ruleSetName,
        operatorType: 'single'
      })
    })
  }

  checkRuleSetType(event, ruleSetIndex, ruleIndex?) {

    //if we add rule to existing ruleset we are not getting ruleIndex from html
    const selectedRuleIndex = ruleIndex ? ruleIndex : this.form.at(ruleSetIndex).value.rules.length - 1; 

    const selectedRuleset = this.form.value[ruleSetIndex]
    const selectedRule = selectedRuleset.rules[selectedRuleIndex];
    
    //RULESET type is score
    if(selectedRuleset.ruleSetType == 'score') {

      //Determine parameters on Rule level
      if(selectedRule.conditional.type == 'logic') {
        this.setParametersForRuleSet(ruleSetIndex, this.mutableParameters);
      }

      if(selectedRule.conditional.type == 'db') {
        this.setParametersForRuleSet(ruleSetIndex, this.mutableParameters);
      }

      if(selectedRule.conditional.type == 'score') {
        this.setParametersForRuleSet(ruleSetIndex, this.getDynamicParameterList(selectedRuleset));
      }
    } else {
      //RULESET type is decision
      this.setParametersForRuleSet(ruleSetIndex, this.parameters);
    }
  }

  getDynamicParameterList(ruleSet): string[] {

    const existingParameters = this.parameters.map(param => param.value);

    // Add parameters for each other ruleSetName, excluding the current decision ruleset
    const ruleSets = this.form.value.filter(r => r.ruleSetName !== ruleSet.ruleSetName && r.ruleSetType !== 'decision');
    ruleSets.forEach(rSet => {
      if (!this.dynamicParameters.includes(rSet.ruleSetName) && !existingParameters.includes(rSet.ruleSetName)) {
        this.dynamicParameters.push({
          name: rSet.ruleSetName,
          value: rSet.ruleSetName,
          operatorType: 'single'
        });
      }
    });

    return this.dynamicParameters;
  }

  setParametersForRuleSet(ruleSetIndex: number, parameters: any[]): void {
    this.parametersMap.set(ruleSetIndex, parameters);
  }

  getParameters(ruleSetIndex: number): any[] {
    return this.parametersMap.get(ruleSetIndex) || [];
  }

  onParameterSelect(event, ruleSetIndex: number, ruleIndex: number): void {
    const selectedParameter = this.parameters.find(param => param.value === event.value);
    this.inputType = event.value === 'sic' ? 'text' : 'number';

     if (selectedParameter) {
      this.filteredOperators[`${ruleSetIndex}_${ruleIndex}`] = this.operators.filter(operator => {
        return selectedParameter.operatorType === 'single' || selectedParameter.operatorType === operator.type;
      });
    } else {
      this.filteredOperators[`${ruleSetIndex}_${ruleIndex}`] = this.operators;
    }
  }

  removeRule(ruleSetIndex, ruleIndex) {
    const ruleSet = this.form.controls[ruleSetIndex];
    (ruleSet.get('rules') as FormArray).removeAt(ruleIndex);
    this.form.markAsDirty();
  }

  removeAllRules(ruleSetIndex) {
    const ruleSet = this.form.controls[ruleSetIndex];
    (ruleSet.get('rules') as FormArray).clear();
    this.form.markAsDirty();
  }

  removeRuleSet(index: number, ruleSetId?: string) {
    this.form.removeAt(index);
    if(ruleSetId) {
      this.loading = true;
      this.deleteRuleByRuleId(ruleSetId)
    } else {
      this.ruleSetDraft = !this.ruleSetDraft;
    }
  }

  setArguments(rule, val, index?) {
    const operator = this.operators.find(operator => operator.value === val);
    let ruleArguments: FormArray;

    if(rule.get('conditional.arguments')) {
      rule.get('conditional.arguments').clear();
      ruleArguments = rule.get('conditional.arguments') as FormArray;
    } else {
      rule.get('conditional.conditional').controls[index].get('arguments').clear();
      ruleArguments = rule.get('conditional.conditional').controls[index].get('arguments') as FormArray;
    }

    if (operator.type === 'single') {
      ruleArguments.push(new Argument('value', rule.value.conditional.conditional))
    } else {
      ruleArguments.push(new Argument('value', rule.value.conditional.conditional));
      ruleArguments.push(new Argument('value', rule.value.conditional.conditional));
    }
  }

  saveRulesets() {
    this.scoreCardService.loadRuleSets(this.form.value)
    .subscribe((r: any) => {
      this.setRuleSets([r])
    });
  }

  logThis() { console.log(this) }

  public updateRuleSetByTenantId(data: ScorecardRuleSet[]): void {
    data.forEach(ruleset => {
      if(ruleset.ruleSetType !== 'decision') {
        ruleset.rules.map((r) => {
          delete r.result.offers;
          delete r.result.applyToAll;
        })
      }
    })
    this.loading = true;
    this.scoreCardService
    .updateRuleSet(this.tenantId, data)
    .subscribe((response) => {
      this.form.markAsPristine();
      this.ruleSetDraft = false;
      this.ruleDraft = false;
      if(response !== null) {
        this.form.clear();
        this.getRules();
      }
    }, err => {
      this.loading = false;
    })
  }

  public deleteRuleByRuleId(ruleId: string): void {
     this.scoreCardService.deleteRuleByRuleId(ruleId).subscribe((res) => {
      this.loading = false;
    })
  }


  public async listDbField(): Promise<void> {
    this.scoreCardService.listDbFields().subscribe((response: any) => {
      this.dbFieldsList = response.rows;
    })
  }

  public deleteAllRulesForTenant(): void {
    this.loading = true;
    this.scoreCardService.deleteRules(this.tenantId).subscribe(() => {
      this.form.controls = [];
      this.getRules();
    })
  }

  public ruleSetTypeChange(type: MatSelectChange, ruleSet: FormGroup): void {
    this.ruleSetType = type.value;

    if(this.ruleSetType == 'score') {
      const scoreWeightFormControl: FormControl = new FormControl(null);
      ruleSet.addControl('scoreWeight', scoreWeightFormControl);
      ruleSet.get('scoreWeight').setValidators(Validators.required);
    } else {
      if (ruleSet.get('scoreWeight')) {
        ruleSet.removeControl('scoreWeight');
        this.form.updateValueAndValidity();
      }
    }
  }

  public addOffer(result: FormGroup, index: number): void {
    const offers: FormArray = result.get('offers') as FormArray;
    offers.push(new ResultOffer([new OfferField([new Argument()])]));
  }

  public addOfferField(offer: FormGroup): void {
    const fields: FormArray = offer.get('fields') as FormArray;
    fields.push( new OfferField([new Argument('value', `arg${fields.length}`)]));
    this.form.markAsTouched();
  }

  public removeOfferField(offer: FormGroup, index: number): void {
    const fields: FormArray = offer.get('fields') as FormArray;
    fields.removeAt(index);
    this.form.markAsTouched();
    this.form.markAsDirty();
  }

  public removeOffer(offers: FormArray, index): void {
    this.form.markAsTouched();
    offers.removeAt(index)
    this.form.markAsDirty();
  }

  public parameterExist(ruleConditional) {
    if(this.operators.hasOwnProperty(ruleConditional)) {
      return true;
    }
    return false;
  }

  public onToggle(event): void {
    this.form.markAsTouched();
  }

  public getInputSuffix(parameterName): string {
    const inputSuffixesMap = {
      tib: "years",
      deposits: "$"
    };

    return inputSuffixesMap[parameterName];
  }

  private setOffersArray(offers: any[]) {
    const offersFormArray = this.fb.array([]);
  
    if (!offers || !Array.isArray(offers)) {
      return offersFormArray;
    }
  
    offers.forEach(offer => {
      const fieldsArray = this.fb.array([]);
  
      if (!offer.fields || !Array.isArray(offer.fields)) {
        return;
      }
  
      offer.fields.forEach(field => {
        fieldsArray.push(
          this.fb.group({
            name: [field.name],
            operator: [field.operator],
            arguments: this.createArgumentsFormArray(field.arguments)
          })
        );
      });
  
      offersFormArray.push(
        this.fb.group({
          fields: fieldsArray
        })
      );
    });
  
    return offersFormArray;
  }
  
  private createArgumentsFormArray(args: any[]): FormArray {
    const argsFormArray = this.fb.array([]);
  
    if (!args || !Array.isArray(args)) {
      return argsFormArray;
    }
  
    args.forEach(argument => {
      argsFormArray.push(
        this.fb.group({
          argName: [argument.argName],
          argType: [argument.argType],
          argValue: [argument.argValue]
        })
      );
    });
  
    return argsFormArray;
  }

  private createConditionalFormArray(conditionalArray: any[]): FormArray {
    const array = this.fb.array([]);

    if(!conditionalArray) {
      return array;
    }

    conditionalArray.forEach(conditional => {
      array.push(
        this.fb.group({
          conditional: [conditional.conditional],
          type: [conditional.type],
          operator: [conditional.operator],
          arguments: this.createArgumentsFormArray(conditional.arguments)
        })
      )
    });
    return array;
  }
}
