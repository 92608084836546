<ng-container [formGroup]="form">
  <div [ngClass]="layout === 'vertical' ? 'col-sm-6 ps-0 pe-0 float-start' : 'col-sm-12 row'">
    <div [ngClass]="layout !== 'vertical' ? 'col-sm-6 ps-0 pe-0 ' : 'col-sm-12 ps-0 pe-0 '">
      <mat-label class="mt-1">Select Funding Method *</mat-label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="fundingMethod" (selectionChange)="onFundingMethodChanged($event)">
          <mat-option *ngFor="let row of fundingMethodsList" [value]="row.value">
            {{ row.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div [ngClass]="layout !== 'vertical' ? 'col-sm-6 ps-2 pe-0 ' : 'col-sm-12 ps-0 pe-0 '" *ngIf="fundingProcessors?.length">
      <mat-label class="mt-1">Select Funding Processor</mat-label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="fundingProcessor">
          <mat-option *ngFor="let row of fundingProcessors" [value]="row" (onSelectionChange)="selectFundingProcessor($event, row.processorAccountName)">
            <span *ngIf="row.automated">{{ row.processor }}{{ row.processorAccountName ? ' - ' + row.processorAccountName : '' }}</span>
            <span *ngIf="!row.automated">{{ row.processor }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="layout === 'vertical' && application?.id" class="col-sm-6 float-end">
    <div class="breakdown-row mb-2" *ngIf="application?.iso?.id">
      <span>ISO: </span><strong class="right-align">{{ application?.iso?.name }}</strong><br>
    </div>
    <div class="breakdown-row">
      <span>Advance amount:</span>
      <span class="right-align">{{ application?.appliedFundingOffer.fundedAmount | currency }}</span>
    </div><br>
    <!-- <div class="breakdown-row">
      <span>Fees</span>
    </div><br> -->
    <div class="breakdown-row" *ngFor="let fee of showNonBalanceFees(application?.appliedFundingOffer.fees)">
      <span>{{ fee.name }}:</span>
      <span class="right-align">{{ fee.amount | currency }}</span>
      <br>
    </div>
    <div class="breakdown-row" *ngIf="application?.refinance">
      <span>Refinance Balance Source:</span>
      <span class="right-align">{{ application?.refinanceBalanceSource | status }}</span><br>
    </div>
    <div class="breakdown-row" *ngIf="application?.refinance">
      <span>Refinance Balance Include Fee:</span>
      <span class="right-align">{{ application?.refinanceBalanceSourceIncludeFees ? 'Yes' : 'No' }}</span><br>
    </div>
    <div class="breakdown-row" *ngIf="application?.refinance">
      <span>Refinance Amount:</span>
      <span class="right-align">{{ application?.refinanceAmount | currency }}</span><br>
    </div>
    <div class="breakdown-row mt-3">
      <span>Disbursement Amount:</span>
      <strong class="right-align">
        {{ application?.appliedFundingOffer.fundedAmount -
        (calculateFees(application?.appliedFundingOffer.fees) + (application && application.refinance ? application.refinanceAmount : 0)) | currency }}
      </strong>
    </div><br>
    <!-- <div class="breakdown-row mt-2">
      <span>
        <strong>{{ application?.appliedFundingOffer.fundedAmount - (application?.appliedFundingOffer.bankFeeAmount + application?.appliedFundingOffer.wireFeeAmount) | currency }}</strong>
        will be deposited into <strong>{{ application?.client?.name }}</strong>'s account.
      </span>
    </div><br> -->

    <div class="breakdown-row mt-4" *ngIf="application?.paymentMethod === 'Split_Lockbox'">
      <span>Split Percent:</span>
      <span class="right-align"><strong>{{ application?.appliedFundingOffer.splitPercent/100 | percent: '1.0-2' }}</strong></span>
    </div>
    <div class="breakdown-row mt-4" *ngIf="application?.paymentMethod !== 'Split_Lockbox'">
      <span>Payment Amount:</span>
      <span class="right-align"><strong>{{ application?.appliedFundingOffer.paymentAmount | currency }}</strong></span>
    </div><br>
    <div class="breakdown-row">
      <span>Commission Amount:</span>
      <span class="right-align"><strong>{{ application?.appliedFundingOffer.commissionAmount | currency }}</strong></span>
    </div><br>
    <div class="breakdown-row">
      <span>Commission Percent:</span>
      <span class="right-align"><strong>{{ application?.appliedFundingOffer.commissionPercent | number : '1.2-2' }}%</strong></span>
    </div><br>
    <div class="breakdown-row" *ngIf="application?.paymentMethod !== 'Split_Lockbox'">
      <span># of Payments:</span>
      <span class="right-align"><strong>{{ application?.appliedFundingOffer.totalPayments }}</strong></span>
    </div><br *ngIf="application?.paymentMethod !== 'Split_Lockbox'">
    <div class="breakdown-row">
      <span>Expected Start Date:</span>
      <span class="right-align"><strong>{{ application?.appliedFundingOffer.expectedStartDate | date: 'shortDate' }}</strong></span>
    </div><br>
    <div class="breakdown-row">
      <span>Payment Frequency:</span>
      <span class="right-align"><strong>{{ application?.appliedFundingOffer.paymentFrequency }}</strong></span>
    </div><br>
    <div class="breakdown-row" *ngIf="application?.appliedFundingOffer.paymentFrequency === 'Weekly'|| application?.appliedFundingOffer.paymentFrequency === 'Biweekly'">
      <span>Payment Day:</span>
      <span class="right-align"><strong class="capitalize">{{ transformLetters(application?.appliedFundingOffer.paymentDays.firstDayOfWeek) }}</strong></span><br>
    </div>
    <div *ngIf="refinances.length">
      <div *ngFor="let refinance of refinances">
        <hr>
        <div class="breakdown-row">
          <span><strong>{{ refinance.source }} Funding</strong></span>
        </div><br>
        <div class="breakdown-row" *ngIf="refinance.source === 'External'">
          <span>Funding Company:</span>
          <span class="right-align"><strong>{{ refinance.companyName }}</strong></span><br>
        </div>
        <div class="breakdown-row">
          <span>Payoff Amount:</span>
          <span class="right-align"><strong>{{ refinance.payoffAmount | currency }}</strong></span><br>
        </div>
        <div class="breakdown-row" *ngIf="refinance.source === 'External'">
          <span>Bank Routing Number:</span>
          <span class="right-align"><strong>{{ refinance.bank.routingNumber }}</strong></span><br>
        </div>
        <div class="breakdown-row" *ngIf="refinance.source === 'External'">
          <span>Bank Account Number:</span>
          <span class="right-align"><strong>{{ refinance.bank.accountNumber }}</strong></span><br>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="layout === 'vertical' ? 'col-sm-6 ps-0 pe-0 float-start' : 'col-sm-12 row'">
    <div [ngClass]="layout !== 'vertical' ? 'col-sm-6 ps-0 pe-0 ' : 'col-sm-12 ps-0 pe-0 '">
      <mat-label class="mt-1">Select Repayment Method *</mat-label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="paymentMethod" (selectionChange)="onPaymentMethodChanged($event)">
          <mat-option *ngFor="let row of repaymentMethodsList" [value]="row.value">
            {{ row.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div [ngClass]="layout !== 'vertical' ? 'col-sm-6 ps-2 pe-0 ' : 'col-sm-12 ps-0 pe-0 '" *ngIf="repaymentProcessors?.length">
      <mat-label class="mt-1">Select Repayment Processor</mat-label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="paymentProcessor">
          <mat-option *ngFor="let row of repaymentProcessors" [value]="row" (onSelectionChange)="selectPaymentProcessor($event, row.processorAccountName)">
            <span *ngIf="row.automated">{{ row.processor }}{{ row.processorAccountName ? ' - ' + row.processorAccountName : '' }}</span>
            <span *ngIf="!row.automated">{{ row.processor }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="breakdown-row pt-2" *ngIf="layout === 'vertical' && application?.client?.voidedCheckName">
      <span>Name As It Appears On The Voided Check:</span>
      <span class="right-align"><strong>{{ application?.client?.voidedCheckName }}</strong></span>
    </div>

    <div class="col-sm-12 mt-1">
      <oiq-bank-selection [layout]="'vertical'" [type]="'client'" [id]="application.client.id" [fundingBankId]="application.fundingBankId"
        [paymentBankId]="application.paymentBankId" (onFundingBankSelected)="onFundingBankSelected($event)"
        (onPaymentBankSelected)="onPaymentBankSelected($event)" [fundingBankVisible]="fundingBankVisible"
        [paymentBankVisible]="paymentBankVisible" *ngIf="application"></oiq-bank-selection>
    </div>

  </div>

</ng-container>
