import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

export class RuleSet extends FormGroup {
  constructor(
    tenantId: number,
    ruleSetName?: string,
    ruleSetType?: string,
    scoreWeight?: number,
    rules?: [],
    useSet?: boolean,
    ruleSetDescription?: string,
  ) {
    super({
      tenantId: new FormControl(tenantId),
      ruleSetName: new FormControl(ruleSetName || '', [Validators.required]),
      ruleSetType: new FormControl(ruleSetType || '', [Validators.required]),
      rules: new FormArray(rules || []),
      useSet: new FormControl(useSet || true),
      ruleSetDescription: new FormControl(ruleSetDescription || '', [Validators.required])
    });
  }
}

export class Argument extends FormGroup {
  constructor(type?: string, name?: string, value?: string) {
    super({
      argType: new FormControl(type),
      argName: new FormControl(name),
      argValue: new FormControl(value, Validators.required)
    });
  }
}

export class Rule extends FormGroup {
  constructor(type?: string) {
    super({
      conditional: type === 'logic' ? 
        new LogicConditional(type, '', [new Conditional('db', '', '', []), new Conditional('db', '', '', [])]) : 
        new Conditional(type || '', '', '', []),
      result: new Result(null)
    });
  }
}

export class Conditional extends FormGroup {
  constructor(
    type: string,
    operator: string,
    conditional: string,
    args: []
  ) {
    super({
      type: new FormControl(type, Validators.required),
      operator: new FormControl(operator, Validators.required),
      conditional: new FormControl(conditional, Validators.required),
      arguments: new FormArray(args)
    });
  }
}

export class LogicConditional extends FormGroup {
  constructor(
    type: string,
    logicOperator: string,
    conditional: any[]
  ) {
    super({
      type: new FormControl(type),
      logicOperator: new FormControl(logicOperator),
      conditional: new FormArray(conditional)
    })
  }
}

export class Result extends FormGroup {
  constructor(score: number) {
    super({
      score: new FormControl(score, Validators.required),
      applyToAll: new FormControl(false),
      offers: new FormArray([])
    });
  }
}

export class ResultOffer extends FormGroup {
  constructor(fields: OfferField[]) {
    super({
      fields: new FormArray(fields)
    })
  }
}

export class OfferField extends FormGroup {
  constructor(_arguments: Argument[]) {
    super({
      name: new FormControl(),
      operator: new FormControl(),
      arguments: new FormArray(_arguments)
    })
  }
}