<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Select White Labels</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    <p class="info-text">Please select the White Labels to display data for.</p>
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="selectedWhiteLabels" placeholder="Select White Labels" multiple>
        <p class="select-all" (click)="checkAllWhiteLabels()">--All--</p>
        <mat-option *ngFor="let whiteLabel of data.whiteLabels" [value]="whiteLabel">
          {{ whiteLabel.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="accent" mat-dialog-close (click)="onSubmit()" [disabled]="selectedWhiteLabels.length === 0">
      Select
    </button>
  </div>
</div>
