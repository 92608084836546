import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'oiq-select-white-labels-dialog',
  templateUrl: './select-white-labels-dialog.component.html',
  styleUrls: ['./select-white-labels-dialog.component.scss']
})
export class SelectWhiteLabelsDialogComponent implements OnInit {
  selectedWhiteLabels: any = [];
  allWhiteLabelsSelected = false;

  constructor(
    public dialogRef: MatDialogRef<SelectWhiteLabelsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.selectedWhiteLabels) {
      this.selectedWhiteLabels = this.data.whiteLabels.filter((wl: any) => {
        return this.data.selectedWhiteLabels.some((swl: any) => swl.id === wl.id);
      });
    }
  }


  onSubmit() {
    const selectedWhiteLabels = this.selectedWhiteLabels.map((wl: any) => {
      return {
        id: wl.id,
        name: wl.name
      }
    });
    this.dialogRef.close(selectedWhiteLabels);
  }

  onCancel() {
    this.dialogRef.close();
  }

  checkAllWhiteLabels() {
    this.allWhiteLabelsSelected = !this.allWhiteLabelsSelected;
    if (this.allWhiteLabelsSelected) {
      this.selectedWhiteLabels = this.data.whiteLabels;
    } else {
      this.selectedWhiteLabels = [];
    }
  }
}
