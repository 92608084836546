import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApplicationService, FundingService } from '@core/services';
import { ApplicationEvent } from '@core/models';

@Component({
  selector: 'oiq-dynamic-status',
  templateUrl: './dynamic-status.component.html',
  styleUrls: ['./dynamic-status.component.scss']
})
export class DynamicStatusComponent implements OnInit {

  @Input()
  applicationId: number;

  @Input()
  disabled: boolean;

  @Input()
  applicationStatus: string;

  @Input()
  fundingId: number;

  @Input()
  fundingStatus: string;

  @Input()
  listView: boolean;

  @Input()
  executeFirst: boolean;

  @Input()
  tooltip: string;

  @Output()
  onStatusUpdate = new EventEmitter<boolean>();

  intercept: string;
  statusEvent: string;

  changeStatuses = [
    {
      'name': 'Underwriting_Review',
      'statuses': [
        { 'name': 'No_Go', 'event': 'No_Go' },
        { 'name': 'Final_Funding_Approval', 'event': 'Send_To_Funding_Approval' }
      ]
    },
    {
      'name': 'Underwriting_Review_Queue',
      'statuses': [
        { 'name': 'No_Go', 'event': 'No_Go' }
      ]
    },
    {
      'name': 'Offer_Applied',
      'statuses': [
        { 'name': 'Contracts_Sent', 'event': 'Send_Contracts' }
      ]
    },
    {
      'name': 'Contracts_Sent',
      'statuses': [
        { 'name': 'Contracts_Received_Incomplete', 'event': 'Contracts_Received_Incomplete' },
        { 'name': 'Contracts_Received_Complete', 'event': 'Contracts_Received_Complete' },
        { 'name': 'Contracts_Awaiting_Stipulations', 'event': 'Contracts_Awaiting_Stipulations' }
      ]
    },
    {
      'name': 'Contracts_Received_Incomplete',
      'statuses': [
        { 'name': 'Contracts_Sent', 'event': 'Send_Contracts' },
        { 'name': 'Contracts_Received_Complete', 'event': 'Contracts_Received_Complete' },
        { 'name': 'Contracts_Awaiting_Stipulations', 'event': 'Contracts_Awaiting_Stipulations' }
      ]
    },
    {
      'name': 'Contracts_Received_Complete',
      'statuses': [
        { 'name': 'Contracts_Sent', 'event': 'Send_Contracts' },
        { 'name': 'Contracts_Received_Incomplete', 'event': 'Contracts_Received_Incomplete' },
        { 'name': 'Contracts_Awaiting_Stipulations', 'event': 'Contracts_Awaiting_Stipulations' }
      ]
    },
    {
      'name': 'Contracts_Awaiting_Stipulations',
      'statuses': [
        { 'name': 'Contracts_Sent', 'event': 'Send_Contracts' },
        { 'name': 'Contracts_Received_Incomplete', 'event': 'Contracts_Received_Incomplete' },
        { 'name': 'Contracts_Received_Complete', 'event': 'Contracts_Received_Complete' },
      ]
    },
    {
      'name': 'Stipulations_Review',
      'statuses': [
        { 'name': 'Contracts_Received_Incomplete', 'event': 'Contracts_Received_Incomplete' },
        { 'name': 'Contracts_Received_Complete', 'event': 'Contracts_Received_Complete' },
        { 'name': 'Contracts_Awaiting_Stipulations', 'event': 'Contracts_Awaiting_Stipulations' }
      ]
    },
  ];

  changeFundingStatuses = [
    {
      'name': 'Healthy',
      'statuses': [
        { 'name': 'Slow_Pay', 'event': 'Slow_Pay', 'intercept': true }
      ]
    },
    {
      'name': 'Slow_Pay',
      'statuses': [
        { 'name': 'Healthy', 'event': 'Healthy' }
      ]
    },
    {
      'name': 'Defaulted',
      'statuses': [
        { 'name': 'Slow_Pay', 'event': 'Slow_Pay', 'intercept': true }
      ]
    }
  ];

  showStatusChangeDropdown = false;

  loading: boolean;

  currentStatus: any;

  constructor(
    private applicationService: ApplicationService,
    private fundingService: FundingService
  ) { }

  ngOnChanges(simpleChange: any) {
    if (this.applicationId) {
      this.currentStatus = this.changeStatuses.filter(status => status.name === this.applicationStatus)[0];
    } else if (this.fundingId) {
      this.currentStatus = this.changeFundingStatuses.filter(status => status.name === this.fundingStatus)[0];
      if (simpleChange['executeFirst'] && simpleChange['executeFirst'].currentValue === false) {
        this.updateStatus( this.intercept, this.statusEvent);
      }
    }
  }

  ngOnInit() {
    // this.currentStatus = this.changeStatuses.filter(status => status.name === this.applicationStatus)[0];
  }

  toggleStatusChangeDropdown(event) {
    event.stopPropagation();

    if (this.showStatusChangeDropdown) {
      this.showStatusChangeDropdown = false;
    } else {
      this.showStatusChangeDropdown = true;
    }
  }

  updateStatus(intercept, statusEvent, metadata?: any): void {

    if (this.applicationId) {
      this.loading = true;

      this.applicationService.sendEvent(this.applicationId, new ApplicationEvent(statusEvent, metadata))
      .subscribe((r: any) => {
        if (!this.listView) {
          this.applicationService.reloadApplication(this.applicationId, true)
          .subscribe((r: any) => {
            this.loading = false;
          });
        } else {
          if (statusEvent === 'Send_Contracts') {
            statusEvent = 'Contracts_Sent';
          }
          this.applicationStatus = statusEvent;
          this.loading = false;
          this.currentStatus = this.changeStatuses.filter(status => status.name === this.applicationStatus)[0];
        }
      });
    } else if (this.fundingId) {
      if (this.executeFirst && intercept) {
        this.intercept = intercept;
        this.statusEvent = statusEvent;
        this.statusUpdated(true);
      } else {
        this.loading = true;

        this.fundingService.updateStatus(this.fundingId, statusEvent)
        .subscribe((r: any) => {
          if (!this.listView) {
            this.fundingService.reloadFunding(this.fundingId, true)
            .subscribe((r: any) => {
              this.loading = false;
            });
          } else {
            this.fundingStatus = statusEvent;
            this.loading = false;
            this.currentStatus = this.changeFundingStatuses.filter(status => status.name === this.fundingStatus)[0];
          }
        });
      }
    }
  }

  statusUpdated(value: boolean) {
    this.onStatusUpdate.emit(value);
  }

}
