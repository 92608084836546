import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '@core/authentication/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ErrorDialogComponent } from "@shared/error-dialog/error-dialog.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { HotjarService } from '@core/services/hotjar.service';

@Component({
  selector: 'oiq-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  loginError: string;
  form: FormGroup;

  otpForm: FormGroup;

  mfaToken = '';
  private formSubmitAttempt: boolean;
  adminRoles = ["ONYXIQ_ADMIN", "SUPER_ADMIN", "ADMIN"];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private hotJarService: HotjarService
  ) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
    this.form = this.fb.group({
      userName: ['', { validators: Validators.compose([Validators.required, Validators.email]), updateOn: 'blur' }],
      password: ['', Validators.required]
    });

    this.otpForm = this.fb.group({
      otp: ['', Validators.required],
      token: [this.mfaToken]
    })
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  openNoWhiteLabelsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.data = "You have no White Labels assigned, and will have a limited experience on the platform. Please contact your administrator.";
    const dialogRef = this.dialog.open(ErrorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }

  onSubmit() {
    this.loginError = '';
    this.authService.killSession();
    if (!this.mfaToken) {
      if (this.form.valid) {
        this.authService.login(this.form.value, this.returnUrl)
          .subscribe((result) => {
            this.authService.createToken(result.apiKey);
            if (result.whiteLabelIds.length === 0 && !this.adminRoles.includes(result.roles[0].name)) {
              this.openNoWhiteLabelsDialog();
            }
            this.hotJarService.init();
          },
            (error) => {
              if (error.message) {
                if (error.message === 'Password expired') {
                  this.loginError = 'Password has expired. You will be sent a password reset link';
                } else {
                  this.loginError = error.message;
                }
              } else if (typeof error === 'string') {
                if (error === 'Password expired') {
                  this.loginError = 'Password has expired. You will be sent a password reset link';
                } else {
                  this.loginError = error;
                }
              } else {
                if (error.token) {
                  this.mfaToken = error.token;
                  this.otpForm.get('token').setValue(this.mfaToken);
                } else {
                  this.loginError = 'An internal server error occurred.';
                }
              }
            },
            () => {
              this.formSubmitAttempt = true;
            }
          );
      }
    } else {
      if (this.otpForm.valid) {
        this.authService.loginWithOtp(this.otpForm.value, this.returnUrl)
          .subscribe((result) => {
            this.authService.createToken(result.apiKey);
            if (result.whiteLabelIds.length === 0 && !this.adminRoles.includes(result.roles[0].name)) {
              this.openNoWhiteLabelsDialog();
            }
          },
            (error) => {
              if (typeof error === 'string') {
                this.loginError = error;
              } else {
                if (error.message) {
                  this.loginError = error.message;
                } else {
                  this.loginError = 'An internal server error occurred.';
                }
              }
            },
            () => {
              this.formSubmitAttempt = true;
            }
          );
      }
    }
  }

}
